.mainContainer {
  display: flex;
  justify-content: center;
  margin-top: 12px;
}
.innerContainer {
  border: 1px solid rgba(0, 0, 0, 0.12);
  width: 36%;
  height: 40%;
  border-radius: 6px;
  padding: 12px;
}
.errMsg {
  color: grey;
  font-size: 14px;
  font-weight: 500;
  padding-top: 14px;
  padding-bottom: 14px;
}
#tryAgainBtn {
  width: 100%;
  background-color: green;
  border-color: green;
  margin-bottom: 12px;
}
#cancelBtn {
  width: 100%;
  background-color: rgb(75, 160, 181);
  border-color: rgb(75, 160, 181);
}

/* Centered container */
.polling-container {
  text-align: center;
  padding: 20px;
}

/* Message box with a responsive width */
.polling-message-box {
  display: inline-block;
  max-width: 90%;
  width: 400px;
  padding: 15px;
  box-sizing: border-box;
}

/* Main message styling */
.polling-main-message {
  font-size: 1.2em;
  margin-top: 10px;
  white-space: nowrap;
}  

/* Sub message styling */
.polling-sub-message {
  font-size: 1em;
  color: #555;
}

/* Error message box styling with neutral tones */
.error-message-box {
max-width: 80%;
margin: 20px auto;
padding: 15px;
background-color: #f9f9f9; /* Subtle gray background */
border: 1px solid #ddd; /* Light border */
border-radius: 8px;
box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
text-align: center;
}

.error-text {
font-size: 1em;
font-weight: bold;
margin: 0;
}

/* Responsive adjustments */
@media (max-width: 600px) {
.polling-main-message {
  font-size: 1em;
}
.polling-sub-message,
.error-text {
  font-size: 0.9em;
}
}
